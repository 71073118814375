import 'magnific-popup';
import moment from 'moment';

jQuery( document ).ready(function($) {


    $.ajax( {
        url: 'https://rest.bandsintown.com/artists/Blood Orange/events?app_id=45PRESS_BO',
        method: 'GET',
        dataType: 'json',
        error: () => {
            alert( 'Error fetching events!' );
        },
        success: data => {
            const events = $( '#events' );
            let html = '';
            if ( data.length ) {
                for ( let event of data ) {
                    let location = event.venue.city + ', ' + event.venue.region;
                    if ( location === ', ' ) {
                        location = '';
                    }
                    html += '<div class="event">';
                    html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM D' ) + '</div>';
                    html += '<div class="event-location">' + location + '</div><div class="event-venue">'+ event.venue.name + '</div>';
                    html += '<div class="event-links">';
                    for ( let offer of event.offers ) {
                        html += '<a href="' + offer.url + '" target="_blank" class="btn">Buy ' + offer.type + '</a>';
                    }
                    html += '</div>';
                    html += '</div>';
                }
                events.html( html );
            }
        }
    });


    // Spotify
    const SMESpotifyPreSave = {
        state: null,
        spotify_user: null,
        url_vars: [],
        getURLVars: function (callback, form_id) {
            let hash;
            const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

            for (let i = 0; i < hashes.length; i++)
            {
                hash = hashes[i].split('=');
                this.url_vars.push(hash[0]);
                this.url_vars[hash[0]] = hash[1];
            }

            this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
            this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
            this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;

            if (typeof form_id !== 'undefined' && this.spotify_user !== null) {
                const form = document.getElementById(form_id);
                form.innerHTML = form.innerHTML + '<input type="hidden" id="spotify_user" name="spotify_user" value="' + this.spotify_user + '" />';
            }

            if (typeof callback === 'function') {
                callback(this.state, this.spotify_user, this.message);
            }
        }
    };

    SMESpotifyPreSave.getURLVars(function(state) {
        if(state === 'thank-you') {
            $.magnificPopup.open({
                items: {
                  src: '#thankyou'
                }, 
                type: 'inline'
            });
        }
    });


    $('.btn-apple').magnificPopup({
        items: {
            src: '#apple-popup',
            type: 'inline'
        }
    });
    
    $('.mfp-inline').magnificPopup({});

    $('.newsletter').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });

    //Apple Form
    $('.btn-apple').on('click', function( e ) { 
        let AmkPlaylists = [];
        let AmkAlbums = [];
        // Create a new SMEAppleMusic object with your parameters
        let sm = new SMEAppleMusic({
            ae: {
                ae: "7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd",
                brand_id: "4023994",
                segment_id: "1604462",
                activities: '{"actions":{"presave":96363},"mailing_list_optins":{"a0S0800000g8BLTEA2":96394}}'
            },
            am: {
              dev_token: "",
              save_mode: "library",
              custom_playlist_name: "",
              new_apple_presave: true,
              resources: {
                playlists: AmkPlaylists,
                albums: AmkAlbums
              }
            },
            sf: {
              form: "210431",
              default_mailing_list: ""
            },
            smf: {
              campaign_id: "210431",
              campaign_key: "1cc794dbf94349cd84a872b3821665bc"    
            }
        });
    
  
        document.getElementById('apple_music_form').addEventListener('submit', function(e) {
            e.preventDefault();
            // Grab the email address value
            const email = document.getElementById('apple_music_email_address').value;
            // Pass the email address and opt in status
            // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
            sm.doActions(email, false, ["a0S0800000g8BLTEA2"]).then(function (res) {
                // Complete - handle your logic here
                $.magnificPopup.open({
                    items: {
                      src: '#thankyou'
                    },
                    type: 'inline'
                });
                
            }).catch(function(err) {
                // Error - handle your logic here
            });
        });
    } );

    //Spotify
    let SMEPreSave = {
        state: null,
        spotify_user: null,
        url_vars: [],
        getURLVars: function (callback, form_id) {
          let hash;
          let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
          for (let i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            this.url_vars.push(hash[0]);
            this.url_vars[hash[0]] = hash[1];
              }
          this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
          this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
          this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
          this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
          this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
          if (typeof callback === 'function') {
            callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
          }
        }
      };
      /* String state
        *  The status of the pre-save.
        *  Int ae_member_id
        *  The Appreciation Engine member ID. This can be passed to the forms processor.
        *  String spotify_user
        *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
        *  String deezery_user
        *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
        */
      SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
        // Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
        if (state === 'success' || state === 'thank-you') {
          $.magnificPopup.open({
            items: {
              src: '#thankyou'
            }, 
            type: 'inline'
          });
        }
    });    
});